<template>
  <div
    class="popup-seminar-detail"
  >
    <button
      class="btn-close"
      @click.prevent="closeModal()"
    >
      닫기
    </button>
    <div class="imgBox" />
    <div class="my-seminar-type">
      <label class="KMA">KMA 연수 교육</label>
    </div>
    <dl>
      <dt>{{ seminar.title }}</dt>
      <dd>평점 {{ seminar.gradepoint.split(' ')[1] }}</dd>
      <dd class="mgt10">
        {{ seminarDate }}
      </dd>
      <dd>{{ seminar.place }}</dd>
    </dl>
    <div class="bottom-fix">
      <p class="tip">
        ※ KMA 연수 교육은 사전 예약이 불가능합니다.
      </p>
      <div>
        <div class="btn-wrap">
          <button
            key="btn-reservation-cancel"
            class="btn-line"
            @click.prevent="cancelMySeminar()"
          >
            MY 세미나 등록 삭제
          </button>
          <button
            class="btn-go-reservation"
            @click.prevent="linkKmaSeminar()"
          >
            자세히 보러가기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { callExternalApi } from '@/core/global/global-liveinfo'

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    seminar: {
      type: Object,
      required: true,
    },
    callType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      startDateTime: null,
      seminarType: 2,
    }
  },
  computed: {
    seminarDate() {
      const dayOfWeek = dayjs(this.seminar.startDate).format('d')
      const date = dayjs(this.seminar.startDate).format('YYYY-MM-DD')

      const startTime = dayjs(this.seminar.startDate).format('HH:mm')
      const endTime = this.seminar.endDate ? dayjs(this.seminar.endDate).format('HH:mm') : ''

      return `${date} (${this.getDayOfWeekName(dayOfWeek)}) ${startTime} ~ ${endTime}`
    },
  },
  created() {
  },
  methods: {
    cancelMySeminar() {
      this.$parent.$parent.showConfirmDialog('해당 일정을 MY 세미나에서 삭제하시겠습니까?', confirm => {
        if (confirm) {
          axios.delete('/fu/webseminar/my', {
            data: {
              webSeminarId: this.id,
              seminarType: this.seminarType
            }
          })
            .then(() => {
              this.closeModal()
              this.reloadDays()
            })
            .catch(() => {
              this.$parent.$parent.showAlertDialog('MY 세미나 삭제에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
            })
        }
      })
    },
    linkKmaSeminar() {
      const eduKmaUrl = 'https://edu.kma.org/edu/schedule_view'

      callExternalApi("LinkUrl", `${eduKmaUrl}?eduidx=${this.id}`)
    },
    closeModal() {
      this.$emit('closeModal', 2)
    },
    reloadDays() {
      this.$emit('reloadDays')
    },
    getDayOfWeekName(dayOfWeek) {
      if (dayOfWeek === '0') { return '일' }
      if (dayOfWeek === '1') { return '월' }
      if (dayOfWeek === '2') { return '화' }
      if (dayOfWeek === '3') { return '수' }
      if (dayOfWeek === '4') { return '목' }
      if (dayOfWeek === '5') { return '금' }
      if (dayOfWeek === '6') { return '토' }
      return ''
    },
  },
}
</script>
