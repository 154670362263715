var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup-seminar-detail" }, [
    _c(
      "button",
      {
        staticClass: "btn-close",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.closeModal()
          },
        },
      },
      [_vm._v(" 닫기 ")]
    ),
    _c("div", { staticClass: "imgBox" }),
    _vm._m(0),
    _c("dl", [
      _c("dt", [_vm._v(_vm._s(_vm.seminar.title))]),
      _c("dd", [
        _vm._v("평점 " + _vm._s(_vm.seminar.gradepoint.split(" ")[1])),
      ]),
      _c("dd", { staticClass: "mgt10" }, [
        _vm._v(" " + _vm._s(_vm.seminarDate) + " "),
      ]),
      _c("dd", [_vm._v(_vm._s(_vm.seminar.place))]),
    ]),
    _c("div", { staticClass: "bottom-fix" }, [
      _c("p", { staticClass: "tip" }, [
        _vm._v(" ※ KMA 연수 교육은 사전 예약이 불가능합니다. "),
      ]),
      _c("div", [
        _c("div", { staticClass: "btn-wrap" }, [
          _c(
            "button",
            {
              key: "btn-reservation-cancel",
              staticClass: "btn-line",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.cancelMySeminar()
                },
              },
            },
            [_vm._v(" MY 세미나 등록 삭제 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn-go-reservation",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.linkKmaSeminar()
                },
              },
            },
            [_vm._v(" 자세히 보러가기 ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "my-seminar-type" }, [
      _c("label", { staticClass: "KMA" }, [_vm._v("KMA 연수 교육")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }