<template>
  <div data-label="제약 웹 세미나">
    <div class="web-seminar-wrap">
      <div class="calendar-info-top">
        <div class="left-col">
          <div class="month-wrap">
            <button
              v-if="!thisMonth"
              class="btn-month-prev"
              @click="prevMonth()"
            >
              이전 월
            </button>
            <p class="now-month">
              {{ query.year }}년 {{ query.month + 1 }}월
            </p>
            <button
              class="btn-month-next"
              @click="nextMonth()"
            >
              다음 월
            </button>
          </div>
        </div>

        <div class="right-col">
          <div class="label-wrap">
            <label class="label-seminar">제약 웹 세미나</label>
            <label class="label-kma">KMA 연수 교육</label>
          </div>
          <div class="select-wrap">
            <select v-model="query.viewType">
              <option value="1">
                전체보기
              </option>
              <option value="2">
                제약 웹 세미나만 보기
              </option>
              <option value="3">
                KMA 연수 교육만 보기
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="calendar-area">
        <table>
          <colgroup>
            <col style="width: auto">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
            <col style="width: 14.28%">
          </colgroup>
          <thead>
            <tr>
              <th>일</th>
              <th>월</th>
              <th>화</th>
              <th>수</th>
              <th>목</th>
              <th>금</th>
              <th>토</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(row, index) in data.rows"
              :key="row"
            >
              <CalendarDay
                v-for="day in data.days.slice(index * 7, index * 7 + 7)"
                :key="day.key"
                :day="day"
                :view-type="query.viewType"
                @openModal="openModal"
              />
            </tr>
          </tbody>
        </table>
      </div>

      <ModalClient
        v-if="modalClient.isShow"
        :id="modalClient.id"
        :seminar="modalClient.seminar"
        :call-type="callType"
        @closeModal="closeModal"
        @reloadModal="reloadModal"
        @reloadDays="reloadDays"
      />

      <ModalKma
        v-if="modalKma.isShow"
        :id="modalKma.id"
        :seminar="modalKma.seminar"
        :call-type="callType"
        @closeModal="closeModal"
        @reloadModal="reloadModal"
        @reloadDays="reloadDays"
      />
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'

import { mapGetters } from 'vuex'
import { setStatistics } from '@/common/statistics/service'
import CalendarDay from '../calendar/Day.vue'
import ModalClient from '../calendar/ModalClient.vue'
import ModalKma from '../calendar/ModalKma.vue'

export default {
  components: {
    CalendarDay,
    ModalClient,
    ModalKma,
  },
  data() {
    return {
      init: {
        today: null,
      },
      query: {
        year: dayjs().year(),
        month: dayjs().month(),
        viewType: '1',
      },
      data: {
        days: [],
        rows: 0,
      },
      modalClient: {
        id: null,
        seminar: null,
        isShow: false,
      },
      modalKma: {
        id: null,
        seminar: null,
        isShow: false,
      },
      callType: 'my',
    }
  },
  computed: {
    thisMonth() {
      return this.init.today.year() === this.query.year && this.init.today.month() === this.query.month
    },
    ...mapGetters({
      params: 'util/getParams',
      userName: 'account/getName',
    }),
  },
  watch: {
    params() {
      if (this.params && this.params.route === this.$route.name) this.openModal(this.params)
    },
    userName(val) {
      if (val === null) {
        this.$router.push({ name: 'app-main' })
      }
    },
  },
  created() {
    this.init = {
      today: dayjs(),
    }

    if (this.params) this.openModal(this.params)
  },
  mounted() {
    this.fetchSeminars(this.query.year, this.query.month)
  },
  methods: {
    nextMonth() {
      let next = dayjs().year(this.query.year).month(this.query.month).date(1)

      next = next.add(1, 'month')

      this.query = {
        year: next.year(),
        month: next.month(),
        viewType: this.query.viewType,
      }

      this.fetchSeminars(this.query.year, this.query.month)
    },
    prevMonth() {
      let prev = dayjs().year(this.query.year).month(this.query.month).date(1)

      prev = prev.subtract(1, 'month')

      this.query = {
        year: prev.year(),
        month: prev.month(),
        viewType: this.query.viewType,
      }

      this.fetchSeminars(this.query.year, this.query.month)
    },
    fetchSeminars(year, month) {
      axios.get(`/fu/webseminar/my/${year}/${month + 1}`)
        .then(rs => {
          this.data = {
            days: rs.data,
            rows: rs.data.length / 7,
          }
        })
        .catch(() => {
          this.$parent.showAlertDialog('세미나 목록 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    fetchClientSeminar(webSeminarId) {
      axios.get(`/fu/webseminar/client/${webSeminarId}`)
        .then(rs => {
          this.modalKma = {
            id: null,
            seminar: null,
            isShow: false,
          }

          this.modalClient = {
            id: Number(webSeminarId),
            seminar: rs.data,
            isShow: true,
          }
        })
        .catch(() => {
          this.$parent.showAlertDialog('세미나 상세내용 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    fetchKmaSeminar(kmaSeminarId) {
      axios.get(`/fu/webseminar/kma/${kmaSeminarId}`)
        .then(rs => {
          this.modalClient = {
            id: null,
            seminar: null,
            isShow: false,
          }

          this.modalKma = {
            id: Number(kmaSeminarId),
            seminar: rs.data,
            isShow: true,
          }
        })
        .catch(() => {
          this.$parent.showAlertDialog('세미나 상세내용 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    openModal(seminar) {
      if (seminar.seminarType === 1) {
        setStatistics(seminar.entry === null ? 119 : seminar.entry, seminar.id)

        this.fetchClientSeminar(seminar.id)
      } else if (seminar.seminarType === 2) {
        this.fetchKmaSeminar(seminar.id)
      }

      if (this.params) this.$store.commit('util/setParams', null)
    },
    closeModal(seminarType) {
      if (seminarType === 1) {
        this.modalClient = {
          id: null,
          seminar: null,
          isShow: false,
        }
      } else if (seminarType === 2) {
        this.modalKma = {
          id: null,
          seminar: null,
          isShow: false,
        }
      }
    },
    reloadModal(seminarType) {
      if (seminarType === 1) {
        this.fetchClientSeminar(this.modalClient.id)
      } else if (seminarType === 2) {
        this.fetchKmaSeminar(this.modalKma.id)
      }
    },
    reloadDays(day, id) {
      const dayIndex = this.data.days.findIndex(e => e.key.split('_')[0] === day)
      const scheduleIndex = this.data.days[dayIndex].schedules.findIndex(e => e.id === id)

      const { startDate } = this.data.days[dayIndex].schedules[scheduleIndex]

      this.data.days[dayIndex].schedules.splice(scheduleIndex, 1)

      this.data.days[dayIndex].key = `${dayjs(startDate).format('YYYYMMDD')}_${this.data.days[dayIndex].schedules.filter(e => e.isReserved).length}`
      // this.fetchSeminars(this.query.year, this.query.month)
    },
  },
}
</script>
